// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-es-acerca-js": () => import("./../src/pages/es/acerca.js" /* webpackChunkName: "component---src-pages-es-acerca-js" */),
  "component---src-pages-es-hola-js": () => import("./../src/pages/es/hola.js" /* webpackChunkName: "component---src-pages-es-hola-js" */),
  "component---src-pages-es-index-js": () => import("./../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-resultados-js": () => import("./../src/pages/es/resultados.js" /* webpackChunkName: "component---src-pages-es-resultados-js" */),
  "component---src-pages-hello-js": () => import("./../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-results-js": () => import("./../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */)
}

